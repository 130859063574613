/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { graphql } from 'gatsby';
import OverviewPage from '../templates/overview-page';
import { T200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { default as Grid, GridItem } from '@atlaskit/grid';
import { DisplayHeading } from '@af/design-system-docs-ui';
import CardLink from '../components/card-link';
import IconTile from '../components/icon-tile';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import {
	CATEGORY_NAMES,
	CATEGORY_SLUGS,
	getPageDescription,
	getPageTitle,
	TYPICAL_CARD_SPAN,
} from '../utils/constants';
import { filterReadyForPreview } from '../utils/filter-ready-for-preview';
import { constructPackagePageURL } from '../utils/construct-package-page-url';
import { Status } from '@af/design-system-docs-ui';
import { statusMapper } from '../utils/status-mapper';

const fullWidthStyles = xcss({ width: '100%' });

const pageTitle = getPageTitle(CATEGORY_NAMES.FOUNDATIONS);
const pageDescription = getPageDescription(CATEGORY_NAMES.FOUNDATIONS);

const FoundationsPage: React.ComponentType<{ data: Record<string, any> }> = ({ data }) => {
	const entries = filterReadyForPreview(data.contents.nodes);
	const categorySlug = CATEGORY_SLUGS.FOUNDATIONS;

	return (
		<OverviewPage
			categorySlug={categorySlug}
			title={pageTitle}
			description={pageDescription}
			heroImageSrc={{
				light: data.heroLight.publicURL,
				dark: data.heroDark.publicURL,
			}}
			entries={data.contents.nodes}
		>
			<Stack space="space.300">
				<Grid hasInlinePadding={false}>
					{entries.map((content) => {
						const status = statusMapper(content);
						const iconGlyph = content.iconGlyphName || 'image-border';
						const urlPath = constructPackagePageURL(content, categorySlug);

						return (
							<GridItem span={TYPICAL_CARD_SPAN} key={content.id}>
								<CardLink key={content.id} to={urlPath}>
									<Box padding="space.300">
										<Stack space="space.200" alignInline="start">
											<IconTile
												glyph={iconGlyph}
												background={token('color.icon.accent.teal', T200)}
											/>
											<Box xcss={fullWidthStyles}>
												<Inline space="space.0" alignBlock="center" spread="space-between">
													<DisplayHeading level="h700">{content.title}</DisplayHeading>
													{status && <Status {...status} />}
												</Inline>
											</Box>
											<Text>{content?.description?.description}</Text>
										</Stack>
									</Box>
								</CardLink>
							</GridItem>
						);
					})}
				</Grid>
			</Stack>
		</OverviewPage>
	);
};

export const query = graphql`
	query {
		contents: allContentfulGuideline(
			sort: { order: ASC, fields: title }
			filter: {
				category: { eq: "Foundations" }
				slug: { ne: null }
				contentfulparent: { id: { eq: null } }
			}
		) {
			nodes {
				id
				slug
				description {
					description
				}
				title
				iconGlyphName
				contentfulparent {
					slug
				}
				status
				readyForPreview
			}
		}
		heroLight: file(relativePath: { eq: "light/foundations@2x.png" }) {
			publicURL
		}
		heroDark: file(relativePath: { eq: "dark/foundations@2x.png" }) {
			publicURL
		}
	}
`;

export default FoundationsPage;
